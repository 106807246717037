const initialState = {
  message: "",
  bgColor: "",
  option: "Upload Image",
  generatedImages: [],
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_MESSAGE":
      return { ...state, message: action.data };
    case "SET_BGCOLOR":
      return { ...state, bgColor: action.data };
    case "SET_OPTION":
      return { ...state, option: action.data };
    case "SET_GENERATEDIMAGES":
      return { ...state, generatedImages: action.data };
    default:
      return state;
  }
};

export default homeReducer;
