import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authReducer from "./reducers/auth";
import thunk from "redux-thunk";
import homeReducer from "./reducers/home";

const rootReducer = combineReducers({
  auth: authReducer,
  home: homeReducer,
});

const store = configureStore({ reducer: rootReducer, middleware: [thunk] });

export default store;
