const LoginData = [
  "./images/1.PNG",
  "./images/2.png",
  "./images/3.png",
  "./images/4.PNG",
  "./images/5.png",
  "./images/6.png",
  "./images/7.PNG",
  "./images/8.PNG",
  "./images/9.PNG",
  "./images/10.PNG",
];

export default LoginData;
