import { setCurrentUser, setToken } from "../creators/authCreators";
import { setMessage, setBgColor } from "../creators/homeCreators";
import axios from "axios";

export const loginRequest = async (formData, dispatch) => {
  try {
    const url = `${window._env_.REACT_APP_API_BASE_URL}/token`;
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const response = await axios.post(url, formData, config);
    const responseData = response.data;
    dispatch(setCurrentUser(formData.username));
    dispatch(setToken(responseData.access_token));
    const token = responseData.access_token;
    localStorage.setItem("token", token);
    dispatch(setMessage("Welcome " + formData.get("username") + "!"));
    dispatch(setBgColor("green"));
    return response;
  } catch (error) {
    dispatch(setMessage("Please check your password and username."));
    dispatch(setBgColor("red"));
    console.error(error);
    return error;
  }
};

export const verifyRequest = async (dispatch) => {
  try {
    const url = `${window._env_.REACT_APP_API_BASE_URL}/verify`;
    const authToken = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    const response = await axios.post(url, "", config);
    const responseData = response.data;
    dispatch(setCurrentUser(responseData.data.username));
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
};
