const initialState = {
  currentUser: null,
  token: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CURRENTUSER":
      return { ...state, currentUser: action.data };
    case "SET_TOKEN":
      return { ...state, token: action.data };
    default:
      return state;
  }
};

export default authReducer;
