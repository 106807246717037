import React, { useEffect, useState } from "react";
import styled, { keyframes, css } from "styled-components";
import "./App.css";

import Home from "./pages/Home";
import Login from "./pages/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUser, setToken } from "./store/creators/authCreators";
import { setMessage, setBgColor } from "./store/creators/homeCreators";
import { verifyRequest } from "./store/actions/authActions";

const TopSlideAnimation = keyframes`
  0% {
    top: -100px;
  }
  50% {
    top: 40px;
  }
  100% {
    top: 30px;
  }
`;

const TopSlideAnimationReverse = keyframes`
  0% {
    top: 30px;
  }
  20% {
    top: 40px;
  }
  100% {
    top: -100px;
  }
`;

const Warning = styled.div`
  width: 50%;
  height: 40px;
  margin: 0 25% 0 25%;
  border-radius: 0.75rem;
  background-color: ${(props) => props.backgroundColor};
  z-index: 99;
  position: fixed;
  top: -200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: "Bricolage Grotesque";
  opacity: 0.9;
  ${(props) =>
    props.message
      ? css`
          animation: ${TopSlideAnimation} 0.5s forwards;
        `
      : css`
          animation: ${TopSlideAnimationReverse} 0.5s forwards;
        `};
`;

const CloseButton = styled.button`
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 10px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const App = () => {
  const dispatch = useDispatch();
  const message = useSelector((state) => state.home.message);
  const bgColor = useSelector((state) => state.home.bgColor);
  const username = useSelector((state) => state.auth.currentUser);

  verifyRequest(dispatch).then((response) => {});
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(setMessage(""));
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, [message, dispatch]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodeToken = jwt_decode(token);
      if (decodeToken.exp * 1000 > Date.now()) {
        // do sth if there is token
      } else {
        localStorage.removeItem("token");
        dispatch(setCurrentUser(null));
        dispatch(setToken(null));
        dispatch(setBgColor("red"));
      }
    } else {
      // no token
    }
  }, [dispatch]);

  return (
    <>
      <Warning backgroundColor={bgColor} message={message}>
        {message}
        {message && (
          <CloseButton
            onClick={() => {
              dispatch(setMessage(""));
            }}
          >
            <i class="bi bi-x-lg"></i>
          </CloseButton>
        )}
      </Warning>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route
              path="/"
              element={
                <Login setMessage={setMessage} setBackgroundColor={bgColor} />
              }
            ></Route>
            <Route
              path="/home"
              element={
                username ? (
                  <Home setMessage={setMessage} setBackgroundColor={bgColor} />
                ) : (
                  <Login setMessage={setMessage} setBackgroundColor={bgColor} />
                )
              }
            ></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
