import React from "react";
import { connect, useSelector } from "react-redux";
import styled from "styled-components";

const Container = styled.div`
  width: calc(100% - 300px);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  top: 0;
  right: 0;
`;

const GalleryContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  overflow-y: auto;
`;

const Image = styled.img`
  width: 300px;
  height: 300px;
  border-radius: 0.75rem;
  margin: 50px;
  overflow: hidden;
  cursor: pointer;
  object-fit: contain;
`;

const Title = styled.div`
  width: 80%;
  height: auto;
  font-size: 50px;
  font-family: "Bricolage Grotesque";
  text-align: center;
  z-index: 1;
  margin: 0 10% 0 10%;
`;

const Gallery = ({ setImageIsClicked }) => {
  const generatedImages = useSelector((state) => state.home.generatedImages);
  const handleImageClicked = (path) => {
    setImageIsClicked(path);
  };

  return (
    <Container>
      <GalleryContainer>
        {generatedImages.map((image, index) => {
          return (
            <Image
              onClick={() => handleImageClicked(image)}
              src={image.data}
            ></Image>
          );
        })}
      </GalleryContainer>
    </Container>
  );
};

export default connect(null, {})(Gallery);
