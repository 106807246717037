import React, { useState } from "react";
import styled from "styled-components";
import Gallery from "../components/Gallery";
import ImageDetailed from "../components/ImageDetailed";
import UploadBox from "../components/UploadBox";

const Container = styled.div`
  filter: ${(props) => (props.imageIsClicked ? "blur(20px)" : "none")};
  pointer-events: ${(props) => (props.imageIsClicked ? "none" : "auto")};

  content: var(--tw-content);
  background: transparent;
  background-image: radial-gradient(#17171712 2px, transparent 0);
  background-size: 15px 15px;
  background-position: 0 -6.5px;
  transition: 0.2s all;
`;

const Home = ({ setMessage, setBackgroundColor }) => {
  const [imageIsClicked, setImageIsClicked] = useState("");
  const [imageId, setImageId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <ImageDetailed
        setBackgroundColor={setBackgroundColor}
        setMessage={setMessage}
        imageIsClicked={imageIsClicked}
        setImageIsClicked={setImageIsClicked}
        imageId={imageId}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
      <Container imageIsClicked={imageIsClicked}>
        <UploadBox
          imageIsClicked={imageIsClicked}
          setImageId={setImageId}
          imageId={imageId}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
        <Gallery setImageIsClicked={setImageIsClicked} imageId={imageId} />
      </Container>
    </>
  );
};

export default Home;
