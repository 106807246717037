const historyItems = [
  {
    HistoryPicture: "",
    HistoryInfoContainer: {
      HistoryDate: "12.11.2023",
      HistoryStatus: "Pending",
      StatusCode: "0",
    },
  },
  {
    HistoryPicture: "",
    HistoryInfoContainer: {
      HistoryDate: "13.02.2023",
      HistoryStatus: "Pending",
      StatusCode: "0",
    },
  },
  {
    HistoryPicture: "",
    HistoryInfoContainer: {
      HistoryDate: "21.03.2023",
      HistoryStatus: "Processing",
      StatusCode: "1",
    },
  },
  {
    HistoryPicture: "",
    HistoryInfoContainer: {
      HistoryDate: "03.12.2022",
      HistoryStatus: "Completed",
      StatusCode: "2",
    },
  },
  {
    HistoryPicture: "",
    HistoryInfoContainer: {
      HistoryDate: "03.12.2022",
      HistoryStatus: "Completed",
      StatusCode: "2",
    },
  },
];

export default historyItems;
