import React, { useState, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  generateRequest,
  getImageIdRequest,
  addRequest,
} from "../store/actions/homeActions";
import { verifyRequest } from "../store/actions/authActions";
import {
  setOption,
  setMessage,
  setBgColor,
} from "../store/creators/homeCreators";
import historyItems from "../data";

const Rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const FadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const ScrollText = keyframes`
  from {
    transform: translateX(100px);
  }

  to {
    transform: var(--translate-to, translateX(calc(0 - 100px)));
  }
`;

const ScaleAndFade = keyframes`
  from {
    transform: scale(0.8);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const Container = styled.div`
  width: ${(props) => (props.option ? "100vw" : "300px")};
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
  overflow: hidden;
  z-index: 2;
`;

const ImageContainer = styled.div`
  width: ${(props) => (props.option ? "100vw" : "300px")};
  height: 100vh;
  overflow: hidden;
  position: absolute;
  transition: all 0.5s;
  z-index: 2;
  background: linear-gradient(to top, #c4c6c4 60%, white);
`;

const BackgroundImage = styled.img`
  width: 100vw;
  height: 100vh;
`;

const UploadBoxContainer = styled.div`
  width: ${(props) => (props.option ? "60%" : "100%")};
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0.75rem;
  transition: all 0.5s, transform 1s;
  z-index: 2;
  position: relative;
`;

const SettingsContainer = styled.div`
  width: 90%;
  height: 80%;
  background-color: #f5f5f5;
  border-radius: 0.75rem;
  display: flex;
  transform: ${(props) =>
    props.option === "History" ? "translateY(100vh)" : "none"};
  transition: 0.5s all;
  position: relative;
`;

const LogoContainer = styled.div`
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  position: absolute;
  top: 30px;
`;

const CustomerLogo = styled.img`
  width: 70px;
  height: auto;
  margin: 20px;
  z-index: 3;
  transform: scale(1.4);
`;

const Logo = styled.img`
  width: 70px;
  height: auto;
  margin: 20px;
  z-index: 3;
  transform: scale(1);
`;

const Line = styled.div`
  height: 90px;
  width: 0;
  border: 1px solid black;
  z-index: 3;
`;

const UploadButton = styled.button`
  border: 1px solid black;
  width: 200px;
  height: 200px;
  margin: 10px;
  background-color: ${(props) =>
    props.selectedImage ? "transparent" : "white"};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.75rem;
  transition: all 0.2s;
  border: ${(props) => (props.selectedImage ? "none" : "2px dashed black")};
  cursor: pointer;
  animation: ${FadeIn} 0.5s ease;
  &:hover {
    background-color: ${(props) =>
      props.selectedImage ? "transparent" : "#c4c6c4"};
  }
`;

const UploadImage = styled.img`
  height: 50px;
  width: 50px;
`;

const SelectedImage = styled.img`
  height: 200px;
  width: 200px;
  border-radius: 0.75rem;
  box-shadow: 0 0 10px 1px lightgray;
  animation: ${FadeIn} 0.5s ease;
  object-fit: contain;
`;

const SelectedImageSmall = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 0.75rem;
  border: 1px solid white;
  object-fit: contain;
`;

const UploadInput = styled.input`
  border: 1px solid black;
  border-radius: 0.75rem;
  display: none;
`;

const GenerateButton = styled.button`
  flex: 1;
  height: 40px;
  margin: 5px;
  padding: 5px;
  border-radius: 0.75rem;
  background: #73c99a;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 14px;
  font-family: "Bricolage Grotesque";
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
  pointer-events: ${(props) =>
    props.imageIsClicked
      ? "none"
      : props.imageIsUploaded
      ? props.isLoading
        ? "none"
        : "auto"
      : "none"};
  opacity: ${(props) => (props.imageIsUploaded ? "1" : "0.5")};
  &:hover {
    background: #18b74d;
  }
`;

const OptionsContainer = styled.div`
  flex: 1;
  height: auto;
  margin: 7px;
  border-radius: 0.75rem;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px 1px lightgray;
`;

const OptionButton = styled.button`
  flex: 1;
  border-radius: 0.75rem;
  padding: 5px 5px 5px 10px;
  margin: 2px 5px 2px 5px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => (props.isClicked ? "#c7e2d5" : "transparent")};
  color: #212529;
  font-size: 14px;
  font-family: "Bricolage Grotesque";
  border: none;
  transition: all 0.2s;
  pointer-events: ${(props) =>
    props.imageIsClicked
      ? "none"
      : props.imageIsUploaded
      ? props.isLoading
        ? "none"
        : "auto"
      : "none"};
  opacity: ${(props) => (props.imageIsUploaded ? "1" : "0.5")};
  cursor: pointer;
  overflow: hidden;
  &:hover {
    background-color: #e8eeeb;
  }
`;

const SettingsActionContainer = styled.div`
  height: auto;
  flex: ${(props) => (props.option ? "2" : "0")};
  overflow: hidden;
  border-radius: 0.75rem;
  margin: ${(props) => (props.option ? "7px" : "0")};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s all;
  position: relative;
`;

const PromptInput = styled.textarea`
  width: 100%;
  height: 100%;
  border-radius: 0.75rem;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  padding-left: 20px;
  box-sizing: border-box;
  line-height: 30px;
  font-family: "Bricolage Grotesque";
  font-size: 16px;
  resize: none;
  animation: ${FadeIn} 0.5s ease;
`;

const Slider = styled.input`
  width: 300px;
  height: 5px;
  background: ${(props) =>
    props.percentage <= 50
      ? `linear-gradient(to left, white ${100 - props.percentage}%, #18b74d ${
          props.percentage
        }%)`
      : `linear-gradient(to right, #18b74d ${props.percentage}%, white ${
          100 - props.percentage
        }%)`};
  border-radius: 0.75rem;
  appearance: none;
  animation: ${FadeIn} 0.5s ease;
  &::-webkit-slider-thumb {
    appearance: none;
    width: 10px;
    height: ${(props) => `calc(20px + ${props.percentage}px * 0.2)`};
    background-color: #18b74d;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
    border-radius: 0.5rem;
    cursor: pointer;
  }
  &::before {
    content: "${(props) => props.percentage}%";
    position: absolute;
    top: ${(props) => `calc(50%  + 30px + ${props.percentage}px * 0.2)`};
    left: ${(props) => `calc((100% - 300px) / 2 + ${props.percentage}px * 3)`};
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 5px;
    border-radius: 0.5rem;
    border: 1px solid green;
    color: black;
    z-index: 44;
  }

  &::after {
    content: "${(props) => props.title}";
    position: absolute;
    top: calc(50% - 50px);
    left: 50%;
    transform: translate(-50%, -50%);

    padding: 5px;
    border-radius: 0.5rem;
    font-size: 16px;
    color: black;
    z-index: 44;
  }
`;

const SliderScreen = styled.div`
  width: 40px;
  height: 30px;
  border-radius: 0.75rem;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PromptScreen = styled.div`
  width: 100px;
  height: 30px;
  border-radius: 0.75rem;
  background-color: white;
  display: flex;
  overflow: hidden;
  position: relative;
  opacity: ${(props) => (props.displayScreen ? (props.prompt ? 1 : 0) : 0)};
  transition: 0.5s all;
`;

const PromptContent = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  height: 100%;
  transform: translateX(100px);
  white-space: nowrap;
  transition: all 1s ease;
  animation: ${ScrollText} 10s infinite linear;
`;

const NextButton = styled.button`
  width: 70px;
  height: 30px;
  border: none;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background-color: #51c478;
  position: absolute;
  bottom: 10%;
  cursor: pointer;
  opacity: ${(props) => (props.display ? "1" : "0")};
  pointer-events: ${(props) => (props.display ? "auto" : "none")};
  transition: 0.2s all;
  &:hover {
    background-color: #18b74d;
  }
`;

const HistoryButton = styled.button`
  width: 100px;
  height: 30px;
  position: absolute;
  left: calc(5%);
  top: 20px;
  border: none;
  border-radius: 0.75rem;
  background-color: #e1d5aa;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 5;
  font-size: 12px;
  transition: 0.2s all;
  &:hover {
    background-color: #e8eeeb;
  }
`;

const ResultsButton = styled.button`
  width: 150px;
  height: 30px;
  position: absolute;
  left: calc(5% + 110px);
  top: 20px;
  border: none;
  border-radius: 0.75rem;
  background-color: #e1d5aa;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 5;
  font-size: 12px;
  transition: 0.2s all;
  &:hover {
    background-color: #e8eeeb;
  }
`;

const HistoryBoxContainer = styled.div`
  width: 90%;
  max-height: 80%;
  background-color: transparent;
  border-radius: 0.75rem;
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  transform: ${(props) =>
    props.option !== "History" ? "translateY(100vh)" : "none"};
  transition: all 0.5s, transform 0s;
  position: absolute;
`;

const HistoryItem = styled.div`
  width: calc(33% - 20px);
  height: 100px;
  border-radius: 0.75rem;
  margin: 10px;
  display: flex;
  padding: 10px;
  opacity: 0;
  box-sizing: border-box;
  background-color: ${(props) => props.bgColor};
  animation: ${ScaleAndFade} 1s ease forwards;
  animation-delay: ${(props) => props.delay}s;
`;

const HistoryPicture = styled.div`
  width: 50%;
  height: 80px;
  border-radius: 0.5rem;
  background-color: gray;
`;

const HistoryInfoContainer = styled.div`
  width: 50%;
  height: 80px;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const HistoryDate = styled.div`
  width: auto;
  height: auto;
  font-size: 12px;
`;

const HistoryStatus = styled.div`
  width: auto;
  height: auto;
  font-size: 12px;
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Loading = styled.div`
  height: 0;
  width: 0;
  padding: 15px;
  border: 6px solid #ccc;
  border-right-color: #888;
  border-radius: 22px;
  animation: ${Rotate} 1s linear infinite;
`;

const LoadingMessage = styled.div`
  width: 100%;
  height: 20px;
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
`;

const LogoutButton = styled.div`
  width: 100px;
  height: 30px;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #c7e2d5;
  z-index: 5;
  font-size: 12px;
  cursor: pointer;
  transition: 0.2s all;
  &:hover {
    background-color: #73c99a;
  }
`;

const TitleContainer = styled.div`
  width: auto;
  display: flex;
  align-items: center;
`;

const InfoIcon = styled.span`
  color: gray;
  margin-left: 10px;
`;

const UploadBox = ({
  imageIsClicked,
  setImageId,
  imageId,
  isLoading,
  setIsLoading,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const option = useSelector((state) => state.home.option);
  const message = useSelector((state) => state.home.message);
  const [prompt, setPrompt] = useState("");
  const [negativePrompt, setNegativePrompt] = useState(
    "deformed, noisy, blurry, distorted, out of focus, poorly drawn"
  );
  const [strength, setStrength] = useState(0.35);
  const [scale, setScale] = useState(7.5);
  const [refStep, setRefStep] = useState(30);
  const [refStepSliderValue, setRefStepSliderValue] = useState(28);
  const [strengthSliderValue, setStrengthSliderValue] = useState(35);
  const [scaleSliderValue, setScaleSliderValue] = useState(26);
  const [selectedImage, setSelectedImage] = useState(null);
  const [promptWidth, setPromptWidth] = useState(0);
  const [negativePromptWidth, setNegativePromptWidth] = useState(0);
  const [historyIsClicked, setHistoryIsClicked] = useState(false);
  const [previousOption, setPreviousOption] = useState("Upload Image");
  const hiddenFileInput = useRef(null);

  const [loadingMessage, setLoadingMessage] = useState("");

  const statusColor = ["#f5f5f5", "#ffe372", "#2bc66f"];

  const handleOption = (selected) => {
    setPreviousOption(selected);
    if (selected === "Upload Image") {
      setSelectedImage(null);
    }
    dispatch(setOption(selected));
  };

  const handleHistory = (click) => {
    setHistoryIsClicked(click);
    if (!click) {
      dispatch(setOption(previousOption));
    } else {
      dispatch(setOption("History"));
    }
  };

  const handleSliderChange = (event, option) => {
    const value = event.target.value;

    if (option === "Quality & Details") {
      const step = 100 / 20;
      setRefStep(parseInt(Math.floor(value / step) * 2 + 20));
      setRefStepSliderValue(value);
    }
    if (option === "Reference Tile Strength") {
      setStrength(value / 100);
      setStrengthSliderValue(value);
    }

    if (option === "Prompt Guidance") {
      const step = 100 / 33;
      setScale(Math.floor(value / step) * 0.5 + 3.5);
      setScaleSliderValue(value);
    }
  };

  const handleUpload = () => {
    hiddenFileInput.current.click();
  };

  const Logout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePrompt = (e) => {
    const text = e.target.value;
    setPrompt(text);
    const promptText = document.getElementById("prompt-content");
    const promptWidth = promptText.offsetWidth + 20;
    setPromptWidth(promptWidth);
  };

  const handleNegativePrompt = (e) => {
    const text = e.target.value;
    setNegativePrompt(text);
    const negativePromptText = document.getElementById(
      "negative-prompt-content"
    );
    const negativePromptWidth = negativePromptText.offsetWidth + 20;
    setNegativePromptWidth(negativePromptWidth);
  };

  const handleContinue = () => {
    verifyRequest();
    setIsLoading(true);
    const payload = {
      image_data: selectedImage,
    };
    getImageIdRequest(payload).then((response) => {
      if (response.status === 200) {
        setImageId(response.data.data.id);
        handleOption("Prompt");
        setIsLoading(false);
      } else {
        dispatch(setMessage("Something went wrong. Please try again."));
        dispatch(setBgColor("red"));
        setIsLoading(false);
        setLoadingMessage("");
        handleOption("Upload Image");
      }
    });
  };

  const handleGenerate = () => {
    if (!prompt) {
      dispatch(setMessage("You need to enter a prompt."));
      dispatch(setBgColor("red"));
    } else {
      setIsLoading(true);
      setLoadingMessage("Processing... This may take up to 4 minutes.");
      const payload = {
        s3_input_id: imageId,
        prompt: prompt,
        negative_prompt: negativePrompt,
        strength: strength === 0 ? 0 : strength / 2,
        guidance_scale: scale,
        num_inference_steps: refStep,
      };
      generateRequest(payload).then((response) => {
        if (response.status === 200) {
          const payload = {
            company_id: 1,
            request_type_id: 1,
            s3_input_id: imageId,
          };
          addRequest(payload, dispatch).then((response) => {
            if (response.status === 200) {
              setIsLoading(false);
              setLoadingMessage("");
              handleOption("");
            }
          });
        } else {
          dispatch(setMessage("Something went wrong. Please try again."));
          dispatch(setBgColor("red"));
          setIsLoading(false);
          setLoadingMessage("");
          handleOption("Upload Image");
        }
      });
    }
  };

  const handleResults = () => {
    handleOption("");
  };

  return (
    <Container option={option}>
      <LogoutButton onClick={Logout}>Log out</LogoutButton>
      <ImageContainer option={option}></ImageContainer>

      <LogoContainer>
        <CustomerLogo src="./images/vitra-logo.png" />
        <Line></Line>
        <Logo src="./images/mythlogo.svg" />
      </LogoContainer>

      <UploadBoxContainer option={option}>
        {/* <HistoryButton
          option={option}
          onClick={() => handleHistory(!historyIsClicked)}
        >
          {historyIsClicked ? "Back" : "See History"}
        </HistoryButton> */}
        {/* {option && (
          <ResultsButton onClick={handleResults}>See Last Result</ResultsButton>
        )} */}
        {historyIsClicked && (
          <HistoryBoxContainer option={option}>
            {historyItems.map((item, index) => (
              <HistoryItem
                delay={index / 30}
                bgColor={statusColor[item.HistoryInfoContainer.StatusCode]}
              >
                <HistoryPicture></HistoryPicture>
                <HistoryInfoContainer>
                  <HistoryDate>
                    {item.HistoryInfoContainer.HistoryDate}
                  </HistoryDate>
                  <HistoryStatus>
                    {item.HistoryInfoContainer.HistoryStatus}
                  </HistoryStatus>
                </HistoryInfoContainer>
              </HistoryItem>
            ))}
          </HistoryBoxContainer>
        )}
        <SettingsContainer option={option}>
          <OptionsContainer>
            <OptionButton
              imageIsClicked={imageIsClicked}
              imageIsUploaded={true}
              onClick={() => handleOption("Upload Image")}
              isClicked={option === "Upload Image"}
              isLoading={isLoading}
            >
              Upload Image
              {selectedImage && <SelectedImageSmall src={selectedImage} />}
            </OptionButton>
            <OptionButton
              imageIsClicked={imageIsClicked}
              imageIsUploaded={option !== "Upload Image"}
              onClick={() => handleOption("Prompt")}
              isClicked={option === "Prompt"}
              isLoading={isLoading}
            >
              <TitleContainer>
                Prompt{" "}
                <InfoIcon title="Specifies the elements or concepts you want in your tile. You can use words or full sentences to describe your preferences.">
                  <i class="bi bi-info-circle"></i>
                </InfoIcon>
              </TitleContainer>
              <PromptScreen displayScreen={!option} prompt={prompt}>
                <PromptContent
                  style={{
                    "--translate-to": `translateX(calc(-${promptWidth}px))`,
                  }}
                  id="prompt-content"
                >
                  {prompt}
                </PromptContent>{" "}
              </PromptScreen>
            </OptionButton>
            <OptionButton
              imageIsClicked={imageIsClicked}
              imageIsUploaded={option !== "Upload Image"}
              onClick={() => handleOption("Exclude From Tile")}
              isClicked={option === "Exclude From Tile"}
              isLoading={isLoading}
            >
              <TitleContainer>
                Exclude From Tile{" "}
                <InfoIcon style={{marginRight: "-15px", zIndex: 2}} title="Use this field to describe details you don't want in your design. It helps in excluding specific features from your tile.">
                  <i class="bi bi-info-circle"></i>
                </InfoIcon>
              </TitleContainer>
              <PromptScreen displayScreen={!option} prompt={negativePrompt}>
                <PromptContent
                  style={{
                    "--translate-to": `translateX(calc(-${negativePromptWidth}px))`,
                  }}
                  id="negative-prompt-content"
                >
                  {negativePrompt}
                </PromptContent>{" "}
              </PromptScreen>
            </OptionButton>
            <OptionButton
              imageIsClicked={imageIsClicked}
              imageIsUploaded={option !== "Upload Image"}
              onClick={() => handleOption("Quality & Details")}
              isClicked={option === "Quality & Details"}
              isLoading={isLoading}
            >
              <TitleContainer>
                Quality & Details{" "}
                <InfoIcon title="Influences the quality of your design. More steps result in higher quality, but it may take longer. Adjust this setting carefully to achieve your desired level of quality.">
                  <i class="bi bi-info-circle"></i>
                </InfoIcon>
              </TitleContainer>
              <SliderScreen>{refStepSliderValue}</SliderScreen>
            </OptionButton>
            <OptionButton
              imageIsClicked={imageIsClicked}
              imageIsUploaded={option !== "Upload Image"}
              onClick={() => handleOption("Reference Tile Strength")}
              isClicked={option === "Reference Tile Strength"}
              isLoading={isLoading}
            >
              <TitleContainer>
                Reference Tile Strength{" "}
                <InfoIcon title="Determines the strength of the reference tile used at the beginning of your design. A higher value will have a more significant impact on your design.">
                  <i class="bi bi-info-circle"></i>
                </InfoIcon>
              </TitleContainer>
              <SliderScreen>{strengthSliderValue}</SliderScreen>
            </OptionButton>
            <OptionButton
              imageIsClicked={imageIsClicked}
              imageIsUploaded={option !== "Upload Image"}
              onClick={() => handleOption("Prompt Guidance")}
              isClicked={option === "Prompt Guidance"}
              isLoading={isLoading}
            >
              <TitleContainer>
                Prompt Guidance{" "}
                <InfoIcon title="Controls the impact of the user-specified prompt on the tile. A higher value makes the tile more closely aligned with the user's directive.">
                  <i class="bi bi-info-circle"></i>
                </InfoIcon>
              </TitleContainer>
              <SliderScreen>{scaleSliderValue}</SliderScreen>
            </OptionButton>
            <GenerateButton
              isLoading={isLoading}
              imageIsClicked={imageIsClicked}
              imageIsUploaded={option !== "Upload Image"}
              onClick={handleGenerate}
            >
              {option ? (
                "Generate"
              ) : isLoading ? (
                <>
                  {" "}
                  <LoadingContainer>
                    <Loading></Loading>
                  </LoadingContainer>
                </>
              ) : (
                "Generate Again"
              )}
            </GenerateButton>
          </OptionsContainer>
          <SettingsActionContainer option={option}>
            {isLoading && (
              <LoadingContainer>
                <Loading></Loading>
                {loadingMessage && (
                  <LoadingMessage>{loadingMessage}</LoadingMessage>
                )}
              </LoadingContainer>
            )}
            {option === "Upload Image" && !isLoading && (
              <>
                <UploadButton
                  onClick={handleUpload}
                  selectedImage={selectedImage}
                >
                  {selectedImage && <SelectedImage src={selectedImage} />}
                  {!selectedImage && <UploadImage src="./images/upload.png" />}
                </UploadButton>
                <UploadInput
                  type="file"
                  ref={hiddenFileInput}
                  onChange={handleImageUpload}
                ></UploadInput>
                <NextButton onClick={handleContinue} display={selectedImage}>
                  Continue
                </NextButton>
              </>
            )}

            {option === "Prompt" && !isLoading && (
              <PromptInput
                onChange={handlePrompt}
                placeholder="What should be your image?"
                defaultValue={prompt}
              ></PromptInput>
            )}

            {option === "Exclude From Tile" && !isLoading && (
              <PromptInput
                onChange={handleNegativePrompt}
                placeholder="What should NOT be your image?"
                defaultValue={negativePrompt}
              ></PromptInput>
            )}

            {option === "Quality & Details" && !isLoading && (
              <>
                <Slider
                  type="range"
                  min="0"
                  max="100"
                  value={refStepSliderValue}
                  percentage={refStepSliderValue}
                  title={"Quality & Details"}
                  onChange={(e) => handleSliderChange(e, "Quality & Details")}
                ></Slider>
              </>
            )}

            {option === "Reference Tile Strength" && !isLoading && (
              <>
                <Slider
                  type="range"
                  min="0"
                  max="100"
                  value={strengthSliderValue}
                  percentage={strengthSliderValue}
                  title={"Reference Tile Strength"}
                  onChange={(e) =>
                    handleSliderChange(e, "Reference Tile Strength")
                  }
                ></Slider>
              </>
            )}

            {option === "Prompt Guidance" && !isLoading && (
              <>
                <Slider
                  type="range"
                  min="0"
                  max="100"
                  value={scaleSliderValue}
                  percentage={scaleSliderValue}
                  title={"Prompt Guidance"}
                  onChange={(e) => handleSliderChange(e, "Prompt Guidance")}
                ></Slider>
              </>
            )}
          </SettingsActionContainer>
        </SettingsContainer>
      </UploadBoxContainer>
    </Container>
  );
};

export default UploadBox;
