export const setMessage = (message) => ({
  type: "SET_MESSAGE",
  data: message,
});

export const setBgColor = (bgColor) => ({
  type: "SET_BGCOLOR",
  data: bgColor,
});

export const setOption = (option) => ({
  type: "SET_OPTION",
  data: option,
});

export const setGeneratedImages = (generatedImages) => ({
  type: "SET_GENERATEDIMAGES",
  data: generatedImages,
});
