import axios from "axios";
import {
  setMessage,
  setBgColor,
  setOption,
  setGeneratedImages,
} from "../creators/homeCreators";

export const getImageIdRequest = async (payload) => {
  try {
    const url = `${window._env_.REACT_APP_API_BASE_URL}/predict/add_s3input`;
    const authToken = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const response = await axios.post(url, payload, config);
    return response;
  } catch (error) {
    console.error("Error while adding to s3: ", error);
    return error;
  }
};

export const generateRequest = async (payload) => {
  try {
    const url = `${window._env_.REACT_APP_API_BASE_URL}/predict/add-aiparameters`;
    const authToken = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const response = await axios.post(url, payload, config);
    return response;
  } catch (error) {
    console.error("Error while adding to s3: ", error);
    return error;
  }
};

export const addRequest = async (payload, dispatch) => {
  try {
    const url = `${window._env_.REACT_APP_API_BASE_URL}/predict/add-request-variant`;
    const authToken = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const response = await axios.post(url, payload, config);
    if (response.status === 200) {
      dispatch(setGeneratedImages(response.data.data.response_list));
    }
    return response;
  } catch (error) {
    console.error("Error in add-request: ", error);
    return error;
  }
};

export const sendParametersRequest = async (payload) => {
  try {
    const url = `${window._env_.REACT_APP_API_BASE_URL}/predict/add-exportparameters`;
    const authToken = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const response = await axios.post(url, payload, config);
    return response;
  } catch (error) {
    console.error("Error in sending export parameters: ", error);
    return error;
  }
};

export const exportRequest = async (payload) => {
  try {
    const url = `${window._env_.REACT_APP_API_BASE_URL}/predict/add-request-export`;
    const authToken = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const response = await axios.post(url, payload, config);
    return response;
  } catch (error) {
    console.error("Error in sending export parameters: ", error);
    return error;
  }
};
