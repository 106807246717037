import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import {
  exportRequest,
  sendParametersRequest,
} from "../store/actions/homeActions";

const Container = styled.div`
  width: 60vw;
  height: 70vh;
  margin: 15vh 20vw 15vh 20vw;
  border-radius: 0.75rem;
  position: fixed;
  background-color: white;
  z-index: 1;
  box-shadow: 0 0 5vw 1px lightgray;
  opacity: ${(props) => (props.imageIsClicked ? "1" : "0")};
  pointer-events: ${(props) => (props.imageIsClicked !== "" ? "auto" : "none")};
  transition: 0.2s all;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CloseButton = styled.button`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: transparent;
`;

const Image = styled.img`
  width: 512px;
  height: 512px;
  margin: 30px;
  object-fit: contain;
`;

const ExportContainer = styled.div`
  flex: 1;
  height: 500px;
  border-radius: 0.75rem;
  margin-right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const SizeContainer = styled.div`
  width: 100%;
  height: auto;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  background-color: #6c757d47;
`;

const InputContainer = styled.div`
  width: auto;
  height: 60px;
  display: flex;
  flex-direction: column;
  margin: 5px;
  border-radius: 0.75rem;
`;

const Input = styled.input`
  height: 30px;
  width: 70px;
  margin: 5px;
  border-radius: 0.75rem;
  border: none;
  outline: 0;
  padding-left: 10px;
  box-sizing: border-box;
  background-color: white;
`;

const Label = styled.label`
  font-size: 14px;
  font-family: "Bricolage Grotesque";
  margin: 5px 0 0 5px;
`;

const Select = styled.select`
  height: 30px;
  width: 70px;
  margin: 5px;
  border-radius: 0.75rem;
  outline: 0;
  padding-left: 10px;
  box-sizing: border-box;
  border: none;
`;

const Option = styled.option``;

const DownloadButton = styled.button`
  width: 100%;
  height: 50px;
  margin: 5px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 0.75rem;
  border: none;
  background-color: #212529;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Bricolage Grotesque";
  pointer-events: ${(props) =>
    props.isLoading ? "none" : props.imageIsClicked ? "auto" : "none"};
  opacity: ${(props) => (props.isLoading ? "0.7" : "1")};
  cursor: pointer;
  &:hover {
    background-color: #212529c2;
  }
`;

const DownloadLink = styled.a`
  display: none;
`;

const NotificationContainer = styled.div`
  width: calc(100% - 600px);
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
`;

const CheckIcon = styled.div`
  width: 100px;
  height: 100px;
  font-size: 6em;
  color: green;
`;

const BigNotification = styled.div`
  width: 100%;
  height: auto;
  text-align: center;
  margin-top: 20px;
  font-size: 20px;
  color: green;
`;

const Notification = styled.div`
  width: 100%;
  height: auto;
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  color: gray;
`;

const ImageDetailed = ({
  imageIsClicked,
  setImageIsClicked,
  imageId,
  setIsLoading,
  isLoading,
}) => {
  const [width, setWidth] = useState(1024);
  const [height, setHeight] = useState(1024);
  const [sizeType, setSizeType] = useState("px");
  const [dpi, setDpi] = useState(300);
  const [exportType, setExportType] = useState("tiff");
  const [notificationActive, setNotificationActive] = useState(false);
  const [notification, setNotification] = useState("");
  const [bigNotification, setBigNotification] = useState("");
  const dispatch = useDispatch();

  const handleWidthSize = (e) => {
    const value = e.target.value;
    setWidth(value);
  };

  const handleHeightSize = (e) => {
    const value = e.target.value;
    setHeight(value);
  };

  const handleDpi = (e) => {
    const value = e.target.value;
    setDpi(value);
  };

  const handleCloseButton = (path) => {
    setImageIsClicked(path);
    setNotificationActive(false);
  };

  const handleSizeType = (e) => {
    const value = e.target.value;
    setSizeType(value);
  };

  const handleExportType = (e) => {
    const value = e.target.value;
    setExportType(value);
  };

  const handleExportWidthHeight = () => {
    let res_width, res_height, downloadConfirmed;
    if (sizeType === "cm") {
        res_width = (dpi * width) / 2.54;
        res_height = (dpi * height) / 2.54;
    } else if (sizeType === "inch") {
        res_width = dpi * width;
        res_height = dpi * height;
    } else {
        res_width = width;
        res_height = height;
    }
    if ((res_width > 20000) || (res_height > 20000)) {
      if (sizeType === "cm") {
          const maxwidth = (20000 * 2.54) / dpi;
          const maxheight = (20000 * 2.54) / dpi;
          downloadConfirmed = window.confirm(`The wanted size (${width}${sizeType} * ${height}${sizeType}) is too large. 
          \nThe maximum size allowed with DPI (${dpi}) is (${Math.round(maxwidth)}${sizeType} * ${Math.round(maxheight)}${sizeType}).
          \nDo you want to download with the maximum allowed size?`
          );
      } else if (sizeType === "inch") {
          const maxwidth = 20000 / dpi;
          const maxheight = 20000 / dpi;
          downloadConfirmed = window.confirm(`The wanted size (${width}${sizeType} * ${height}${sizeType}) is too large. 
          \nThe maximum size allowed with DPI (${dpi}) is (${Math.round(maxwidth)}${sizeType} * ${Math.round(maxheight)}${sizeType}).
          \nDo you want to download with the maximum allowed size?`
          );
      } else {
          downloadConfirmed = window.confirm(`The wanted size (${width}${sizeType} * ${height}${sizeType}) is too large. 
            \nThe maximum size allowed is (20000${sizeType} * 20000${sizeType}).
            \nDo you want to download with the maximum allowed size?`
          );
      }

      if (downloadConfirmed) {
        res_width = 20000;
        res_height = 20000;
        res_width = Math.round(res_width);
        res_height = Math.round(res_height);
        return [res_width, res_height, true];
      } else {
        res_width = 1024;
        res_height = 1024;
        return [res_width, res_height, false];
      }
    } else {
      res_width = Math.round(res_width);
      res_height = Math.round(res_height);
      return [res_width, res_height, true];
    }
  };
  const handleExport = (imageIsClicked) => {    
    const [res_width, res_height, confirmed] = handleExportWidthHeight();
    if (confirmed) {
      let payload = {
        dpi: dpi,
        format: exportType,
        height: res_height,
        width: res_width,
        tile_url: imageIsClicked.name,
      };
      setIsLoading(true);

      sendParametersRequest(payload)
        .then((response) => {
          if (response.status === 200) {
            setNotificationActive(true);
            setBigNotification("We got your request!");
            setNotification(
              "It will take approximately 15 minutes to process this request. After each operation, download link will be sent to your email account. Thank you for your patient!"
            );
            payload = {
              company_id: 1,
              request_type_id: 2,
              s3_input_id: imageId,
              tile_url: imageIsClicked.name,
            };
            exportRequest(payload).then((response) => {
              if (response.status === 200) {
                setIsLoading(false);
              }
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <Container imageIsClicked={imageIsClicked}>
      <CloseButton onClick={() => handleCloseButton("")}>
        <i class="bi bi-x-lg"></i>
      </CloseButton>
      <Image src={imageIsClicked.data} />
      {!notificationActive && (
        <ExportContainer>
          <SizeContainer>
            <InputContainer>
              <Label>Width:</Label>
              <Input
                type="number"
                min="0"
                value={width}
                onChange={handleWidthSize}
              ></Input>
            </InputContainer>
            <InputContainer>
              <Label>Height:</Label>
              <Input
                type="number"
                min="0"
                value={height}
                onChange={handleHeightSize}
              ></Input>
            </InputContainer>
            <InputContainer>
              <Label>Size Type:</Label>
              <Select onChange={handleSizeType}>
                <Option value="px">px</Option>
                <Option value="cm">cm</Option>
                <Option value="inch">inch</Option>
              </Select>
            </InputContainer>

            <InputContainer>
              <Label>DPI:</Label>
              <Input
                type="number"
                min="0"
                value={dpi}
                onChange={handleDpi}
              ></Input>
            </InputContainer>
            <InputContainer>
              <Label>Export Type:</Label>
              <Select onChange={handleExportType}>
                <Option value="tiff">tiff</Option>
                <Option value="png">png</Option>
                <Option value="jpeg">jpeg</Option>
              </Select>
            </InputContainer>
          </SizeContainer>
          <DownloadButton
            onClick={() => handleExport(imageIsClicked)}
            isLoading={isLoading}
            imageIsClicked={imageIsClicked}
          >
            Export
          </DownloadButton>
          <DownloadLink id="download-link"> </DownloadLink>
        </ExportContainer>
      )}
      {notificationActive && (
        <NotificationContainer>
          <CheckIcon>
            <i class="bi bi-check-circle-fill"></i>
          </CheckIcon>
          <BigNotification>{bigNotification}</BigNotification>
          <Notification>{notification}</Notification>
        </NotificationContainer>
      )}
    </Container>
  );
};

export default ImageDetailed;
